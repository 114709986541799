import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A traditional customer support strategy requires a lot of resources in terms of manpower. Agents are needed in order to respond to each displeased customer looking to your company for a solution to their problem, and it’s vital they receive that support. According to a study by American Express, `}<a parentName="p" {...{
        "href": "https://www.businesswire.com/news/home/20171215005416/en/WellActually-Americans-Customer-Service"
      }}>{`33 percent of consumers`}</a>{` consider switching companies after receiving poor service just one time.Unfortunately, support agents have constraints and human error is a factor. In order to provide better service and handle more tickets, companies are turning to chatbots.`}</p>
    <p>{`This year at SuperBot, Dashbot gathered a panel of experts currently developing customer support chatbots to share some of their insights gained through their own experience in this field.`}</p>
    <h2>{`Why build a chatbot?`}</h2>
    <p>{`When was the last time you were delighted by a customer support experience? If you can’t remember, you’re not alone. Paul Lasserre from `}<a parentName="p" {...{
        "href": "https://appfoundry.genesys.com/#/filter/purecloud"
      }}>{`Genesys`}</a>{` believes there’s room for improvement for customer experience leveraging technology.`}</p>
    <p>{`“The customer experience industry is all about helping companies answer questions and solve issues,” said Lasserre. “It turns out the same questions for a given business reappear very often, so it’s a fantastic area of application for machine learning.”`}</p>
    <p>{`That repetition of similar questions is something Mehmet Orgut from `}<a parentName="p" {...{
        "href": "https://turo.com/"
      }}>{`Turo`}</a>{` sees a lot. As a car sharing platform, Turo needs to gather and verify information from customers before addressing the support issue. A chabot is able to take over this mundane set of tasks and speed up the process of providing a satisfying experience. Customers are more likely to ask for help when they know the process will be quick and painless. TurboTax, whose goal is to encourage their customers to seek the support they need when filing their taxes— a notoriously onerous task— implemented a chatbot for that very reason.`}</p>
    <p>{`Moderator Van Baker from `}<a parentName="p" {...{
        "href": "https://www.gartner.com"
      }}>{`Gartner`}</a>{` noted that customer service is the most common use case he sees for chatbots. In fact, the Gartner Hype Cycle for Customer Service and Engagement predicts that chatbots will reach the Plateau of Productivity in `}<a parentName="p" {...{
        "href": "https://www.gartner.com/smarterwithgartner/4-trends-gartner-hype-cycle-customer-service-customer-engagement/"
      }}>{`two to five years`}</a>{`.`}</p>
    <h2>{`Be data driven`}</h2>
    <p>{`The general consensus of our panel was to start small when first conceptualizing your chatbot. In order to decide which use cases to address, Orgut advised that developers should analyze the data from their current support solution. When Turo `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/customer-service/"
      }}>{`ran an analysis`}</a>{` on what intents they were getting the most, they found that a large portion of their support tickets were FAQ’s and transactional requests like changing the date of a reservation, which are simple enough tasks to build a chatbot around.`}</p>
    <p>{`Nikos Ioannou from `}<a parentName="p" {...{
        "href": "https://turbotax.intuit.com/"
      }}>{`TurboTax`}</a>{` made data integrations a priority before even launching the TurboTax Assistant. In order to ensure the Assistant had an effect on customers completing their taxes, Ioannou tracked interaction rate as well as how well the chatbot was understanding each customer’s intent. Keeping an eye on the chatbot’s data allowed Ioannou to fine-tune conversation flows and optimize the customer journey.`}</p>
    <p>{`Lasserre also notes that it’s important to track business outcomes associated with your chatbot as a way to measure success. Metrics like customer satisfaction and sales will play huge roles in showcasing to your stakeholders that a chatbot was an investment worth making and an investment that should continue.`}</p>
    <p>{`Embrace escalation`}</p>
    <hr></hr>
    <p>{`There are a few misconceptions out there about what makes a successful chatbot. Some might think that 100 percent containment rate is a sign that your chatbot is working really well, but containment doesn’t equate to questions answered or issues solved. Chatbots are difficult to get right, and it would be a challenge to say the least to build one that could address the most complex support tickets. Some issues also just require a human touch. Ioannou champions this philosophy. He recommends escalating as soon as its apparent that the chatbot doesn’t have content relevant to the customer.`}</p>
    <p>{`“Routing is key for us,” he said. “We’re not in the business of containing customers; we’re in the business of answering questions.”`}</p>
    <p>{`If a customer requires an agent to get that answer, it’s no trouble at all. In the event of escalation, TurboTax’s chatbot will have already gathered the necessary information to route that customer to the agent best-suited to the situation and armed them with context and relevant information. The result is a delightful experience from beginning to end, which leads to higher `}<a parentName="p" {...{
        "href": "https://blog.emolytics.com/customer-experience/customer-satisfaction-score-csat-kpi/"
      }}>{`CSAT scores`}</a>{` and `}<a parentName="p" {...{
        "href": "https://hbr.org/2014/10/the-value-of-keeping-the-right-customers"
      }}>{`reduced customer churn`}</a>{`. Lasserre adds that the combination of a chatbot and live agents will be critical in the future to deal with the rising volume of support interactions.`}</p>
    <p>{`Your customers aren’t the only ones who are going to benefit. Agents will no longer have to deal with the more mundane tasks. According to Orgut, they’ll instead get to spend their time on the more fulfilling work of emphasizing with the customer and tailoring their experience. Not only will customer satisfaction increase, but so will agent satisfaction and happy agents are less likely to look for a new job. By reducing employee turnover, you’ll be able to devote resources usually spent on recruiting on other ventures. The company reduces costs in two areas, agents enjoy their jobs more, but most importantly, your customers are better served. A win-win-win situation.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+customer+service+panel%20"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      